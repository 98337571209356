<template>

  <div
    class="is-flex
           is-align-items-center
           is-justify-content-space-between
           pt-5
           pb-5
           title_container"
  >

    <div>
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul class="m-0">
          <li><router-link to="/bo/restaurants">Mes établissements</router-link></li>
          <li class="is-active mt-0">
            <a href="#" aria-current="page">{{ restaurant && restaurant.name }}</a>
          </li>
        </ul>
      </nav>
    </div>

    <v-tour v-if="isMenuPage" name="myTour" :steps="steps" :options="{ highlight: true }">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            class="p-4 box"
          >

            <div slot="header">
              <p
                class="has-text-weight-semibold has-text-centered has-text-grey-darker"
                v-html="tour.steps[tour.currentStep].header"
              />
            </div>

            <div slot="content">
              <p
                class="is-size-6 has-text-justified has-text-grey my-5"
                v-html="tour.steps[tour.currentStep].content"
                style="width: 400px;"
              />
              <img
                v-if="tour.steps[tour.currentStep].img"
                :src="require(`@/assets/img/${tour.steps[tour.currentStep].img}`)"
                alt="tutorial GIF"
              >
            </div>

            <div slot="actions">
              <div class="my-3 is-flex is-justify-content-center">
                <div
                  v-for="(s, sindex) in tour.steps"
                  :key="s.currentStep"
                  class="tour_dot"
                  :class="{ 'tour_dot_current_step': sindex === tour.currentStep }"
                />
              </div>
              <div class="is-flex is-justify-content-space-between">
                <b-button
                  label="Terminer"
                  type="is-secondary"
                  rounded
                  expanded
                  outlined
                  @click="tour.stop"
                  v-if="tour.isLast"
                  size="is-small"
                />
                <b-button
                  label="Passer"
                  type="is-ghost"
                  rounded
                  size="is-small"
                  @click="tour.skip"
                  v-if="!tour.isLast"
                />
                <b-button
                  label="Suivant"
                  type="is-secondary"
                  outlined
                  rounded
                  size="is-small"
                  @click="tour.nextStep"
                  v-if="!tour.isLast"
                />
              </div>
            </div>

          </v-step>
        </transition>
      </template>
    </v-tour>

    <div
      v-if="!isMobile"
      class="is-flex is-justify-content-flex-end is-align-items-center"
    >

      <b-button
        type="is-secondary"
        icon-left="eye"
        rounded
        outlined
        :expanded="isMobile"
        @click="goToClientView"
        v-if="!isMobile"
      >
        Visualiser en ligne
      </b-button>

      <b-button
        v-if="isMenuPage"
        class="ml-3"
        label="Tutoriel"
        type="is-light"
        icon-left="school"
        rounded
        @click="playTour"
      />
    </div>

    <div v-else>
      <b-dropdown
        aria-role="list"
        aria-label="more options"
        class="is-pulled-right card-header-icon p-0"
        position="is-bottom-left"
      >
        <template #trigger>
          <b-icon icon="dots-vertical" class="is-clickable" />
        </template>
        <b-dropdown-item aria-role="see menu online" @click="goToClientView">
          <div class="is-flex is-align-items-center">
            <b-icon icon="eye" class="mr-3" />
            Visualiser en ligne
          </div>
        </b-dropdown-item>
        <!-- <b-dropdown-item v-if="isMenuPage" aria-role="start tour" @click="playTour">
          <div class="is-flex is-align-items-center">
            <b-icon icon="school" class="mr-3" />
            Tutoriel
          </div>
        </b-dropdown-item> -->
      </b-dropdown>
    </div>

  </div>

</template>

<script>
export default {
  props: {
    restaurant: {
      type: Object,
    },
    isMobile: {
      type: Boolean,
    },
  },
  data: () => ({
    showEditRestaurantIcon: false,
    isMenuPage: false,
    steps: [
      {
        target: '#v-step-0',
        header: 'Ajouter un menu',
        content: 'Pour commencer ajouter un ou plusieurs menu (ex: Burgers, Salades, Desserts...etc)',
        img: 'tuto_menu_add_menu.gif',
        params: {
          enableScrolling: false,
          placement: 'left',
        },
      },
      {
        target: '#v-step-1',
        header: 'Modifier un menu',
        content: 'A tout moment vous pouvez modifier ou supprimer votre menu (nom, statut de publication, type de menu PDF ou numérique).',
        img: 'tuto_menu_delete_menu.gif',
        params: {
          enableScrolling: false,
          placement: 'right',
        },
      },
      {
        target: '#v-step-2',
        header: 'Ajouter une catégorie',
        content: 'Une fois le menu créé, vous pourvez commencer à ajouter une ou plusieurs catégories à l\'interieur.',
        img: '',
        params: {
          enableScrolling: false,
          placement: 'right',
        },
      },
      {
        target: '#v-step-3',
        header: 'Modifier une catégorie',
        content: 'A tout moment vous pouvez modifier ou supprimer une catégorie (nom, statut de publication).',
        img: '',
        params: {
          enableScrolling: false,
          placement: 'right',
        },
      },
      {
        target: '#v-step-4',
        header: 'Statut de publication et type de menu',
        content: 'Les icônes apparaissant à droite d\'un menu ou d\'une catégorie vous donne une indication sur le statut de publication ainsi que le type de menu (menu pdf/image ou numérique).',
        img: '',
        params: {
          enableScrolling: false,
          placement: 'right',
        },
      },
      {
        target: '#v-step-5',
        header: 'Drag and Drop',
        content: 'A tout moment déplacer l\'ordre de vos menus ou catégories en cliquant dessus et en les déplaçant à la position désirée.',
        img: 'tuto_menu_drag_and_drop.gif',
        params: {
          enableScrolling: false,
          placement: 'right',
        },
      },
    ],
  }),
  created() {
    this.isMenuPage = this.$route.name === 'BO_RestaurantMenu';
  },
  watch: {
    $route(to) {
      this.isMenuPage = to.name === 'BO_RestaurantMenu';
    },
  },
  methods: {
    goToClientView() {
      const { restaurantId } = this.$route.params;
      const routeData = this.$router.resolve({
        name: 'Restaurant',
        params: { restaurantId },
      });

      window.open(routeData.href, '_blank');
    },
    goToMenuEditor() {
      const { restaurantId } = this.$route.params;

      this.$router.push({
        name: 'BO_MenuCreator',
        params: { restaurantId },
      });
    },
    goToRestaurantPage() {
      const { restaurantId } = this.$route.params;

      this.$router.push({
        name: 'BO_Restaurant',
        params: { restaurantId },
      });
    },
    playTour() {
      // eslint-disable-next-line dot-notation
      this.$tours['myTour'].start();
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .v-step {
    max-width: 450px;
  }

  .tour_dot {
    width: 11px;
    height: 11px;
    border: 1px solid lightgray;
    border-radius: 50%;
    margin: 2px;
  }

  .tour_dot_current_step {
    border: none;
    width: 16px;
    height: 12px;
    border-radius: 6px;
    background-color: #62d4b3;
  }
</style>