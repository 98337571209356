<template>

  <div class="column is-narrow">

    <b-sidebar
      v-if="!isMobile"
      type="is-white"
      position="static"
      :mobile="'reduce'"
      :expand-on-hover="true"
      :fullheight="true"
      :fullwidth="false"
      :overlay="false"
      :right="false"
      :reduce="true"
      :open="true"
    >
      <b-menu>
        <b-menu-list>
          <b-menu-item
            v-for="item in menuItems"
            :key="item.id"
            :active="isActive(item.name)"
            @click="() => onMenuItemClick(item)"
          >
            <template #label>
              <div class="is-flex is-align-items-center">
                <b-icon
                  class="is-pulled-left mr-3"
                  :icon="item.icon"
                  size="is-medium"
                />
                {{ item.label }}
              </div>
            </template>
          </b-menu-item>
        </b-menu-list>
      </b-menu>
    </b-sidebar>

    <div v-else>
      <b-field>
        <b-radio-button
          v-for="item in menuItems"
          :key="item.id"
          v-model="selectedItem"
          @click.native="() => onMenuItemClick(item)"
          :native-value="item"
          type="is-secondary"
          expanded
        >
          <b-icon :icon="item.icon" class="m-0" />
        </b-radio-button>
      </b-field>

      <h1 class="is-size-5 has-text-grey-dark">{{ selectedItem && selectedItem.label }}</h1>
    </div>

  </div>

</template>

<script>
export default {
  props: {
    isMobile: {
      type: Boolean,
    },
  },
  data: () => ({
    selectedItem: null,
    open: true,
    menuItems: [
      {
        id: 1,
        label: 'Général',
        icon: 'storefront-outline',
        name: 'BO_RestaurantGeneral',
      },
      {
        id: 2,
        label: 'Ma carte',
        icon: 'silverware-fork-knife',
        name: 'BO_RestaurantMenu',
      },
      {
        id: 3,
        label: 'QR Code',
        icon: 'qrcode',
        name: 'BO_RestaurantQrCode',
      },
    ],
  }),
  created() {
    this.selectedItem = this.menuItems.find((item) => this.$route.name === item.name);
  },
  methods: {
    onMenuItemClick(item) {
      this.selectedItem = item;
      this.$emit('item-click', item);
    },
    isActive(routeName) {
      return this.$route.name === routeName;
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep li {
    list-style-type: none;
  }

  ::v-deep .menu-list a.is-active {
    background-color: transparent;
    color: #62d4b3;
  }

  ::v-deep .menu-list a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0;
  }

  ::v-deep .b-sidebar .sidebar-content.is-mini {
    width: 40px;
  }

  .menu-list {
    margin: 0 !important;
  }
</style>