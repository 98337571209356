<template>

  <div
    v-if="restaurant"
    :class="{ 'px-6' : !isMobile, 'px-4' : isMobile }"
  >

    <app-restaurant-header
      :restaurant="restaurant"
      :isMobile="isMobile"
    />

    <div class="columns pt-4">

      <app-restaurant-sidebar
        @item-click="navigateTo"
        :isMobile="isMobile"
      />

      <keep-alive>
        <transition :name="transitionName" mode="out-in">
          <router-view
            :restaurant="restaurant"
            :isMobile="isMobile"
            @update-restaurant="restaurant = $event; updateRestaurant();"
            @delete-image="restaurant = $event;"
            @add-image="restaurant = $event;"
            class="child-view"
          />
        </transition>
      </keep-alive>

    </div>

  </div>

</template>

<script>
import RestaurantHeader from '@/components/back_office/RestaurantHeader.vue';
import RestaurantSideBar from '@/components/back_office/RestaurantSideBar.vue';

export default {
  components: {
    'app-restaurant-header': RestaurantHeader,
    'app-restaurant-sidebar': RestaurantSideBar,
  },
  data: () => ({
    restaurant: null,
    windowInnerWidth: window.innerWidth,
  }),
  created() {
    const { restaurantId } = this.$route.params;

    this.fetchRestaurant(restaurantId);
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
  computed: {
    isMobile() {
      return this.windowInnerWidth < 768;
    },
    transitionName() {
      return this.$store.getters.transitionName;
    },
  },
  methods: {
    onResize() {
      this.windowInnerWidth = window.innerWidth;
    },
    navigateTo(item) {
      if (this.$route.name !== item.name) {
        this.$router.push({ name: item.name });
      }
    },
    async fetchRestaurant(restaurantId) {
      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });

      try {
        const res = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/restaurants/${restaurantId}`,
        );
        this.restaurant = res.data;
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
      } finally {
        loadingComponent.close();
      }
    },
    async updateRestaurant() {
      try {
        const { restaurant } = this;

        await this.$http.put(
          `${process.env.VUE_APP_API_URL}/restaurants/${restaurant._id}`,
          restaurant,
        );
        this.$buefy.toast.open({
          message: 'Restaurant mis à jour.',
          type: 'is-success',
          position: 'is-bottom',
        });
      } catch (err) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenu lors de l\'enregistrement des menus.',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .child-view {
    transition: all .1s cubic-bezier(.55,0,.1,1);
  }

  .slide-left-enter, .slide-right-leave-active {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  .slide-left-leave-active, .slide-right-enter {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  ::v-deep li {
    list-style-type: none;
  }

  @media(max-width: $tablet) {
    .columns.is-reversed-mobile {
      display: flex;
      flex-direction: column-reverse;
    }
  }
</style>